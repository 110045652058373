<template>
  <!--
    The view for the TemplateList-component.
    Used to display the the templates that hold the messages that can be send to people with the SmsWizard.
  -->
  <Portlet
    :title="$t('menu.templates')"
    class="smsManagement"
    icon="clone"
  >
    <template slot="buttons">
      <div class="btn-group btn-group-sm">
        <button
          class="btn btn-sm btn-secondary"
          @click="downloadExport()"
        >
          <font-awesome-icon
            class="mr-1"
            icon="download"
          />
          <span>
            {{ $t('smsManagementComp.downloadTranslations') }}
          </span>
        </button>
        <button
          class="btn btn-sm btn-primary"
          @click="showAddTemplate"
        >
          <font-awesome-icon
            class="mr-2"
            icon="plus"
          />
          <span>{{ $t('add') }}</span>
        </button>
      </div>
    </template>
    <TemplateList
      ref="templateList" 
      @reloadAuditLogs="reloadAuditLogs"
    />
    <DatabaseAuditLogList
      ref="databaseAuditLogList"
      :is-sms-templates="true"
      @reload="reloadTemplateList"
    /> 
  </Portlet>
</template>

<script>
export default {
  name: 'SmsManagement',
  components: {
    TemplateList: () => import('@/components/SmsManagement/TemplateList.vue'),
    DatabaseAuditLogList: () => import('@/components/DatabaseAuditLogs/DatabaseAuditLogsList.vue')
  },
  metaInfo () {
    return {
      title: this.$t('smsManagement')
    }
  },
  methods: {
    reloadAuditLogs () {
      this.$refs.databaseAuditLogList.reloadAuditLogs();
    },
    reloadTemplateList () { 
      this.$refs.templateList.getAllTemplates();
    },
    showAddTemplate () {
      this.$refs.templateList.showAddTemplate();
    },
    downloadExport () {
      this.axios.get(`/SmsManagement/GetExport`)
        .then((response) => {
          let csv = response.data;
          let type = response.headers['content-type'];
          // get filename from response.headers.content-disposition
          let disposition = response.headers['content-disposition'];
          let filename = '';
          var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
          var matches = filenameRegex.exec(disposition);
          if (matches != null && matches[1]) {
            filename = matches[1].replace(/['"]/g, '');
          }
          // create data for csv-file
          let fileData = 'data:' + type.replace(' ', '') + ',' + csv;
          let file = encodeURI(fileData);
          // create link to file and auto-open the link
          let link = document.createElement('a');
          link.setAttribute('href', file);
          link.setAttribute('download', filename);
          link.click();
        });
    }
  }
}
</script>